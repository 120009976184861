
import { Options, mixins } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IApp, IUser } from '@/utils/types'
import { ACTION_APP, ACTION_USER } from '@/store/actions'
import { UserModel } from '@/models/user-model'

import AppMixin from './mixins/AppMixin.vue'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onCancel', 'update:onSave'],
})
export default class AppForm extends mixins(AppMixin) {
  @Prop({})
  modelValue!: IApp

  filterOptions: IUser[] = []

  @Watch('$store.getters.users')
  users() {
    const result: IUser[] = cloneDeep(this.$store.getters.users)
    result.forEach((element) => {
      element.label = element.display_name + ' || ' + element.email
    })
    return result
  }

  get form() {
    return this.modelValue
  }

  set form(value: IApp) {
    this.$emit('update:modelValue', value)
  }

  @Prop({ default: true })
  inModal!: boolean

  get requiredRule() {
    const requiredRule = {
      appName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.app_name'),
      }),
      chanelAccessToken: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_access_token'),
      }),
      chanelSecret: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_secret'),
      }),
      chanelAccessTokenTest: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_access_token_test_mode'),
      }),
      chanelSecretTest: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_secret_test_mode'),
      }),
    }
    return requiredRule
  }

  get isNew() {
    return !this.form?._id
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  async filterEmailFn(val: string, update: Function) {
    const searchKey = val.toLowerCase()
    update(async () => {
      if (!val) {
        this.filterOptions = this.users()
      } else {
        this.filterOptions = this.users().filter((p) => p.label?.indexOf(searchKey) !== -1)
      }
    })
  }

  getLINEProfile(account_id) {
    const url = `https://page.line.me/?accountId=${account_id}&openQrModal=true`
    return url
  }

  getQRCode(account_id) {
    const url = `https://qr-official.line.me/sid/m/${account_id}.png`
    return url
  }

  getWebhookURL(app_prefix) {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/callback/${app_prefix}`
    return url
  }

  getWebhookURLTest(app_prefix) {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/callback/test/${app_prefix}`
    return url
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    if (this.isNew) {
      success = await dispatch(ACTION_APP.ADD_NEW, {
        ...this.form,
      })
    } else {
      success = await dispatch(ACTION_APP.UPDATE, {
        _id: this.form._id,
        ...this.form,
      })
    }

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.$emit('update:onSave')
  }

  @Emit('update:onCancel')
  onCancel() {
    return true
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }

  async created() {
    await this.$store.dispatch(ACTION_USER.LOAD_ITEMS)
    this.filterOptions = this.users()
    this.loading = false
  }
}
